
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.input-color {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  &__no-value {
    opacity: 0.05;
  }
}
/* -webkit */
.input-color::-webkit-color-swatch-wrapper {
  padding: 0;
}

.input-color::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

/* firefox */
.input-color::-moz-focus-inner {
  border: none;
  padding: 0;
  border-radius: 50%;
}

.input-color::-moz-color-swatch {
  border: none;
  border-radius: 50%;
}
.priorities .esmp-table__thead-th:last-child {
  width: 56px;
}
.priorities .esmp-table__tbody-td:last-child {
  text-align: center;
}
